import React from "react";
function BannerImage() {
  return (
    <div>
      <section className="headerInnerbox">
        <div className="addbox">Banner Image</div>
      </section>
    </div>
  );
}
export default BannerImage;
